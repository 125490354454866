/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"elektroinstalace v bance"}>
        <Column className="pb--30 pt--30" name={"9rckns6j51i"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Elektroinstalační práce v bance"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"V této zakázce se jednalo o kompletní přestavbu vnitřních prostor banky. Administrativní zázemí, sociální zařízení, vstupní hala i jednotlivé kóje a kanceláře, včetně veškerého osvětlení. \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"y4fkdvoy9c"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/e40ad2559b144850a54e0063533890f8_s=2000x_.jpg 2000w"} position={{"x":"-28.981723237597887%","y":"-1.8276762402088773%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/c23677c097a34713adac68653d33e474_s=2000x_.jpg 2000w"} position={{"x":"-14.360313315926895%","y":"-1.0443864229765014%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/71154a6396594e4ba06acff0d9ee5c1a_s=2000x_.jpg 2000w"} position={{"x":"-42.55874673629247%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/11759/f1e61ec737af49678db9a32873454656_s=2000x_.jpg 2000w"} position={{"x":"-100%","y":"-7.610966057441253%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/a7b258b9ca854fa189cd29a44b3ab594_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/a7b258b9ca854fa189cd29a44b3ab594_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/a7b258b9ca854fa189cd29a44b3ab594_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/a7b258b9ca854fa189cd29a44b3ab594_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/a7b258b9ca854fa189cd29a44b3ab594_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}